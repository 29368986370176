<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <b-img :height="logoHeight" :src="url_logo" alt="logo" />
          </span>
          <h2 class="brand-text mb-0"></h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';
import store from '@/store/index';

export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      url_logo: null,
      logoHeight: '',
      dark: '',
      theme: store.state.appConfig.layout.skin,
    };
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
      appLogoImageDark,
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  created() {
    this.getLogo();
  },
  methods: {
    getLogo() {
      
      if (this.company.logo_image) {
        this.logoHeight = '62px';
        this.url_logo = this.returnUrlS3(this.company.logo_image);
      } else {
        this.logoHeight = '46px';
        if (store.state.appConfig.layout.skin === 'dark') {
          
          this.url_logo = this.appLogoImageDark;
        } else {
          this.url_logo = this.appLogoImage;
        }
      }
    },
  },
};
</script>

<style></style>
