export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Relatórios',
    route: 'reports',
    icon: 'FileIcon',
  },
  {
    title: 'Treinamentos',
    route: 'training-list',
    icon: 'BookOpenIcon',
  },
]
